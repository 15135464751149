<template>
  <div class="error-page">
    <div class="error-page__container">
      <p class="error-page__title">404</p>
      <p class="error-page__subtitle h3">Not found</p>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    Footer
  },
  data () {
    return {
      themeClass: '--dark'
    }
  },
  computed: {},
  methods: {},
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'about'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  }
}
</script>
